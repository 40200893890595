import React from "react";
import { router } from "dva";
import { UnorderedListOutlined } from "@ant-design/icons";
import { Affix, Menu, Drawer, } from "antd";
import { template, getLocalStorage, setLocalStorage } from "@reco-m/core";
import { ViewComponent, mtaH5Click } from "@reco-w/core-ui";
// import { parkhelperText } from "@reco-w/common-common";
import { Namespaces, headerModel } from "@reco-w/layout-models";

export namespace PageHeader {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> { }

    export interface IState extends ViewComponent.IState, headerModel.StateType { }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.header;

        get parkcode() {
            return this.props.match!.params.parkcode;
        }

        componentDidMount() {
            this.dispatch({ type: "input", data: { current: getLocalStorage("selectedMenu") } });

            const width = document.documentElement.clientWidth;
            if (width < 770) {
                $(".container-scrollable").on("scroll", function () {
                    if (($(".container-scrollable").scrollTop() as any) >= 60) {
                        $(".head-menu").addClass("phone-header")
                    } else {
                        $(".head-menu").removeClass("phone-header")
                    }
                });
            }
        }

        /**
         * 渲染目录栏
         * @returns
         */

        handleClick = (e) => {
            setLocalStorage("selectedMenu", e.key);
            this.dispatch({ type: "input", data: { current: e.key, drawerState: false } });
        };

        getSelectedKeys() {
            const selectedMenuText = getLocalStorage("selectedMenu");
            let selectedMenu = selectedMenuText;
            // console.log(selectedMenuText,selectedMenu)

            return [selectedMenu || "/home"];
        }
        showMenu(e) {
            this.dispatch({ type: "input", data: { drawerState: e } });
        }

        renderSubMenuTitle(title) {
            return <span className="size-18">{title}</span>;
        }
        renderMenuItem(key, link, event, title, flag?): React.ReactNode {
            return (
                <Menu.Item key={key}>
                    <router.Link
                        to={link}
                        className={flag ? "size-18" : ""}
                        onClick={() => {
                            mtaH5Click(event);
                        }}
                    >
                        {title}
                    </router.Link>
                </Menu.Item>
            );
        }

        renderMenu(): React.ReactNode {
            const { state } = this.props,
                drawerState = state!.drawerState;
            return (
                <>
                    <Menu onClick={this.handleClick} selectedKeys={this.getSelectedKeys()} mode="horizontal" className="index-header">
                        <Menu.Item key={"/home"}>
                            <router.Link to={"/home"}>首页</router.Link>
                        </Menu.Item>
                        <Menu.Item key={"/aboutus"}>
                            <router.Link to={"/aboutus"}>关于瑞谷</router.Link>
                        </Menu.Item>
                        <Menu.Item key={"/software"}>
                            <router.Link to={"/software"}>瑞谷软件</router.Link>
                        </Menu.Item>
                        <Menu.Item key={"/scientific"}>
                            <router.Link to={"/scientific"}>瑞谷科创</router.Link>
                        </Menu.Item>
                        <Menu.Item key={"/yunqi"}>
                            <router.Link to={"/yunqi"}>瑞谷云企</router.Link>
                        </Menu.Item>
                        <Menu.Item key={"/classiccase"}>
                            <router.Link to={"/classiccase"}>客户案例</router.Link>
                        </Menu.Item>
                        <Menu.Item key={"/information"}>
                            <router.Link to={"/information"}>新闻中心</router.Link>
                        </Menu.Item>
                        <Menu.Item key={"/about"}>
                            <router.Link to={"/about"}>加入我们</router.Link>
                        </Menu.Item>
                    </Menu>

                    <div className="phone menu">
                        <div className="down-icon">
                            <UnorderedListOutlined onClick={() => this.showMenu(true)} />
                        </div>
                    </div>
                    <Drawer placement="right" closable={false} width={"80%"} maskClosable onClose={() => this.showMenu(false)} open={drawerState}>
                        <Menu className="mobile-menu" onClick={this.handleClick} selectedKeys={this.getSelectedKeys()} mode="inline">
                            <Menu.Item key={"/home"}>
                                <router.Link to={"/home"} onClick={() => this.showMenu(false)}>
                                    首页
                                </router.Link>
                            </Menu.Item>
                            <Menu.Item key={"/aboutus"}>
                                <router.Link to={"/aboutus"}>关于瑞谷</router.Link>
                            </Menu.Item>
                            <Menu.Item key={"/software"}>
                                <router.Link to={"/software"}>瑞谷软件</router.Link>
                            </Menu.Item>
                            <Menu.Item key={"/scientific"}>
                                <router.Link to={"/scientific"}>瑞谷科创</router.Link>
                            </Menu.Item>
                            <Menu.Item key={"/yunqi"}>
                                <router.Link to={"/yunqi"}>瑞谷云企</router.Link>
                            </Menu.Item>
                            <Menu.Item key={"/classiccase"}>
                                <router.Link to={"/classiccase"}>客户案例</router.Link>
                            </Menu.Item>
                            <Menu.Item key={"/information"}>
                                <router.Link to={"/information"}>新闻中心</router.Link>
                            </Menu.Item>
                            <Menu.Item key={"/about"}>
                                <router.Link to={"/about"}>加入我们</router.Link>
                            </Menu.Item>
                        </Menu>
                    </Drawer>
                </>
            );
        }

        render(): React.ReactNode {
            return (
                <div className="head-menu">
                    <Affix offsetTop={0}>
                        <div className="header new-header">
                            <div className="head-logo">
                                <img src={"assets/images/gw/logo.svg"} alt="" />
                            </div>
                            <div className="container">
                                <div className="flex width-full">
                                    <div className="flex-item">{this.renderMenu()}</div>
                                </div>
                            </div>
                            {/* <div className="home-search">
                                <Search
                                    onSearch={value => console.log(value)}
                                />
                            </div> */}
                        </div>
                    </Affix>
                </div>
            );
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.header]);
}
