import React from "react";
import { template } from "@reco-m/core";
import { ViewComponent } from "@reco-w/core-ui";
import { Namespaces, footerModel } from "@reco-w/layout-models";
import { Row, Col } from "antd";

export namespace PageFooter {
    export interface IProps<S extends IState = IState> extends ViewComponent.IProps<S> { }

    export interface IState extends ViewComponent.IState, footerModel.StateType { }

    export class Component<P extends IProps = IProps, S extends IState = IState> extends ViewComponent.Base<P, S> {
        namespace = Namespaces.footer;

        render() {
            return (
                <div className="footer">
                    <div className="footer-content">
                        <div className="container">
                            <Row>
                                <Col xs={24} sm={24} md={5}>
                                    <div className="footer-logo mt20">
                                        <img src="assets/images/gw/footer-logo.png" />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={10}>
                                    <div className="contact-info mt10">
                                        <div>
                                            <i className="icon iconzuobiaofill mr5" />
                                            上海市浦东新区秋月路26号矽岸国际7号楼
                                        </div>
                                        <div className="mt8">
                                            <i className="icon icondianhua1 mr5" />
                                            400-026-9898
                                        </div>
                                        <div className="mt8">
                                            <i className="icon icon39 mr5" />
                                            service@bitech.cn
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={8} sm={8} md={3}>
                                    <div className="footer-code">
                                        <img src="assets/images/gw/code1.jpg" />
                                        <div className="mt5">瑞谷集团公众号</div>
                                    </div>
                                </Col>
                                <Col xs={8} sm={8} md={3}>
                                    <div className="footer-code">
                                        <img src="assets/images/gw/code2.png" />
                                        <div className="mt5">瑞谷云企公众号</div>
                                    </div>
                                </Col>
                                <Col xs={8} sm={8} md={3}>
                                    <div className="footer-code">
                                        <img src="assets/images/gw/code3.png" />
                                        <div className="mt5">瑞谷科创公众号</div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="footer-desc">
                        <div className="container">
                            <span>Copyright © 2019-2023 瑞谷集团官网</span>
                            <a className="ml25" href="https://beian.miit.gov.cn/" target="_blank">备案号：沪ICP备15022387号-6</a>

                            <a className="ml25" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502402059"
                                target="_blank">
                                <i className="fwicon"></i>沪公网安备31011502402059号
                            </a>
                        </div>
                    </div>
                </div>
            );
        }
    }

    export const Page = template(Component, (state) => state[Namespaces.footer]);
}
